import { NextPageContext } from 'next'
import { apm } from '@elastic/apm-rum'

import Button from '@/atoms/Button/Button'
import Link from '@/atoms/Link/Link'
import Layout from '@/templates/Layout/Layout'
import { STATUS_404 } from '@/utils/constants/status-code'

interface Props {
  err: (Error & { statusCode: string }) | null | undefined
}

function Error({ err }: Props) {
  return (
    <Layout title="Internal Server Error" canonical="">
      <section
        className="max-width-desktop  |  flex flex-col lg:flex-row items-center justify-around gap-10  |  mx-auto px-5 py-20"
        style={{ minHeight: '60vh' }}
      >
        <div className="text-center lg:text-left">
          <h1 className="mb-10">
            <span className="h1 font-bold  |  block  |  mb-5">
              {err?.statusCode ?? 500}
            </span>
            <span>{err?.message}</span>
          </h1>
          <Link href="/" className="block">
            <Button>Go Back to Home</Button>
          </Link>
        </div>
        <img className="h-full" src="/assets/icons/404Icon.svg" alt="Error" />
      </section>
    </Layout>
  )
}

Error.getInitialProps = ({ res, err, pathname }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : STATUS_404
  if (err) {
    apm.captureError(err)
    apm.addLabels({ pathname })
  }
  return { err, statusCode }
}

export default Error
